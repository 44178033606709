<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
export default {
  page: {
    title: "Nouvelle requête de maintenance",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Nouvelle requête de maintenance",
      types: [
        {
          id: "plumbing",
          title: "Plomberie",
        },

        {
          id: "electricity",
          title: "Electrique",
        },

        {
          id: "carpentry",
          title: "Menuiserie",
        },

        {
          id: "glass_work",
          title: "Travail du verre",
        },

        {
          id: "other",
          title: "Autre",
        },
      ],
      urgencies: [
        {
          id: "urgent",
          title: "Urgent",
        },

        {
          id: "should_be_done_soon",
          title: "Devrait être fait bientôt",
        },

        {
          id: "can_wait_vacation",
          title: "Peut attendre les vacances",
        },
      ],
      locations: [
        {
          id: "room",
          title: "Chambre",
        },

        {
          id: "office",
          title: "Bureau",
        },

        {
          id: "kitchen",
          title: "Cuisine",
        },
      ],
      rooms: [],
      offices: [],
      kitchens: [],
      maintenanceReqForm: {
        maintType: "",
        maintUrgency: "",
        maintLocation: "",
        maintRoom: "",
        maintOffice: "",
        maintRestaurant: "",
        maintComments: "",
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
        {
          text: "Requêtes",
          href: "/",
        },
        {
          text: "Nouvelle requête de maintenance",
          active: true,
        },
      ],
    };
  },

  validations: {
    maintenanceReqForm: {
      maintType: { required },
    },
  },

  created() {
    // this.fetchSuppliersList();
    this.fetchRoomsList();
    this.fetchOfficesList();
  },

  methods: {
    fetchRoomsList() {
      this.$http
        .post("/infrastructure/rooms/list")
        .then((res) => (this.rooms = res.data.original.list))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },

    fetchOfficesList() {
      this.$http
        .post("/infrastructure/office/list")
        .then((res) => (this.offices = res.data.original.list))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },

    fetchKitchensList() {
      this.$http
        .post("/contracts/suppliers/list")
        .then((res) => (this.kitchens = res.data.original.list))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },

    fetchSuppliersList() {
      this.$http
        .post("/contracts/suppliers/list")
        .then((res) => (this.suppliers = res.data.original.list))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      var router = this.$router;
      this.$http
        .post("/ops/requests/maintenance/store", this.maintenanceReqForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
                router.push({ name: "requests.maintenance.index" });
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="formrow-inputCity">Type de maintenance *</label>

                    <multiselect v-model="maintenanceReqForm.maintType" :searchable="true" track-by="id" label="title" :options="types" placeholder="Select maintenance type" :allow-empty="false">
                      <template slot="singleType" slot-scope="{ type }">{{ type.title }}</template>
                    </multiselect>
                    
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-password-input">Urgence *</label>
                    <multiselect v-model="maintenanceReqForm.maintUrgency" :searchable="true" track-by="id" label="title" :options="urgencies" placeholder="Select maintenance urgency" :allow-empty="false">
                      <template slot="singleUrgency" slot-scope="{ urgency }">{{ urgency.title }}</template>
                    </multiselect>
                    <div
                      v-if="
                        submitted && $v.maintenanceReqForm.maintUrgency.$error
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.maintenanceReqForm.maintUrgency.required"
                        >Le prestataire est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="formrow-inputCity">Lieu de maintenance *</label>
                    <multiselect v-model="maintenanceReqForm.maintLocation" :searchable="true" track-by="id" label="title" :options="locations" placeholder="Select maintenance location" :allow-empty="false">
                      <template slot="singleLocation" slot-scope="{ location }">{{ location.title }}</template>
                    </multiselect>
                  </div>
                </div>
              </div>

              <div class="row">
                <div
                  class="col-lg-4"
                  v-if="maintenanceReqForm.maintLocation.id == 'room'"
                >
                  <div class="form-group">
                    <label for="formrow-inputCity">Chambre *</label>
                    <multiselect v-model="maintenanceReqForm.maintRoom" :searchable="true" track-by="id" label="number" :options="rooms" placeholder="Select a room" :allow-empty="false">
                      <template slot="singleRoom" slot-scope="{ room }">{{ room.number }}</template>
                    </multiselect>
                  </div>
                </div>

                <div
                  class="col-md-4"
                  v-if="maintenanceReqForm.maintLocation.id == 'office'"
                >
                  <div class="form-group">
                    <label for="formrow-password-input">Bureau *</label>
                    <multiselect v-model="maintenanceReqForm.maintOffice" :searchable="true" track-by="id" label="number" :options="offices" placeholder="Select an office" :allow-empty="false">
                      <template slot="singleOffice" slot-scope="{ office }">{{ office.number }}</template>
                    </multiselect>
                    <div
                      v-if="submitted && $v.maintenanceReqForm.maintType.$error"
                      class="invalid-feedback"
                    >
                      <span
                        v-if="!$v.maintenanceReqForm.contractSupplier.required"
                        >Le prestataire est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-4"
                  v-if="maintenanceReqForm.maintLocation.id == 'kitchen'"
                >
                  <div class="form-group">
                    <label for="formrow-inputCity">Cuisine *</label>
                    <multiselect v-model="maintenanceReqForm.maintKitchen" :searchable="true" track-by="id" label="title" :options="kitchens" placeholder="Select a kitchen" :allow-empty="false">
                      <template slot="singleKitchen" slot-scope="{ kitchen }">{{ kitchen.title }}</template>
                    </multiselect>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="formrow-inputCity">Description *</label>
                    <textarea
                      v-model="maintenanceReqForm.maintComments"
                      class="form-control"
                      cols="30"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div>
                <button
                  class="btn btn-primary"
                  :disabled="$v.maintenanceReqForm.$invalid"
                  type="submit"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>